import React from 'react'
import { Link } from 'react-router-dom';

const CartaCatalogo = (props) => {

    const {id, nombre, precio, img} = props;

  return (
    <Link to={`/catalogo/${props.id}`} >
        <div className='w-full h-full bg-gray-300 flex gap-2 rounded-md p-4 min-w-[380px] hover:scale-105 duration-300 shadow-lg shadow-blue-500'>
            <div className='min-h-[200px]'>
                <img src={img} alt="" className='h-[200px] min-w-[140px] rounded-lg' />
            </div>
            <div className='text-bold p-2 w-[90%]'>
                <h1 className='text-2xl font-bold'>{nombre}</h1>
                <p className='text-lg font-bold'>{precio}</p>
                <hr />
                <p className=''>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, pariatur.
                </p>
            </div>
        </div>
    </Link>

  )
}

export default CartaCatalogo