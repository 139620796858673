import React from 'react'
import Navbar2 from '../components/Navbar2'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CartaCatalogo from '../components/CartaCatalogo'
import { Link } from 'react-router-dom'
import Remulus from '../assets/HeroNosotros.webp'
import Procyon from '../assets/HeroNosotrosDos.webp'

const catalogo = [
  {
    id : 1,
    nombre : 'Cabaña Remulus',
    precio : '40.000 por día',
    img : Remulus,
  },
  {
    id : 2,
    nombre : 'Cabaña Procyon',
    precio : '40.000 por día',
    img : Procyon
  },
  {
    id : 3,
    nombre : 'Cabaña 3 pisos',
    precio : '333.333'
  }
]

const Catalogo = () => {
  return (
    <div>
        
        <Navbar2/>
        <Navbar/>

        <div className='h-full w-full'>
            <div className='min-h-[80vh]'>
                <h1 className='font-bold text-xl text-center pt-8'>Catálogo Cabañas</h1>
                
                  <div className='grid grid-cols-1 md:grid-cols-2 w-[90%] mx-auto gap-8 py-8 '>
                    {catalogo.map((c) => {
                      return <CartaCatalogo id={c.id} nombre={c.nombre} precio={c.precio} img={c.img}/>
                    })}
                  </div>


            </div>
            
        </div>

        <Footer/>


    </div>
  )
}

export default Catalogo