import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import Inicio from './Pages/Inicio';
import Catalogo from './Pages/Catalogo';
import Nosotros from './Pages/Nosotros';
import VistaCabana from './Pages/VistaCabana';


const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Inicio/>
  },
  {
    path: "/catalogo",
    element: <Catalogo/>,

  },
  {
    path: "/catalogo/:id",
    element: <VistaCabana/>
  },
  {
    path: "/nosotros",
    element: <Nosotros/>
  }
])


root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
