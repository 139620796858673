import React from 'react'
import Navbar from '../components/Navbar'
import Navbar2 from '../components/Navbar2'
import Footer from '../components/Footer'

import Remulus from '../assets/HeroNosotros.webp'
import Procyon from '../assets/HeroNosotrosDos.webp'

const catalogo = [
  {
    id : 1,
    nombre : 'Cabaña Remulus',
    precio : '40.000 por día',
    img : Remulus,
  },
  {
    id : 2,
    nombre : 'Cabaña Procyon',
    precio : '40.000 por día',
    img : Procyon,
  },
  {
    id : 3,
    nombre : 'Cabaña 3 pisos',
    precio : '333.333',
    img : Remulus,
  }
]


const VistaCabana = () => {

  

  return (
    <div>
        
        <Navbar2/>
        <Navbar/>
        <div className='min-h-[60vh]'>
            <img src='' alt="" />
            <h1 className='pt-40'>
              hola
            </h1>
            <p></p>

        </div>
        <Footer/>
    </div>
  )
}

export default VistaCabana