import React from 'react'
import Navbar from '../components/Navbar'
import Navbar2 from '../components/Navbar2'
import Footer from '../components/Footer'
import HeroNosotros from '../assets/HeroNosotros.webp'
import HeroNosotros2 from '../assets/HeroNosotrosDos.webp'

const Nosotros = () => {
  return (
    <div>
        <Navbar/>
        <Navbar2/>
        <div className='w-full h-full py-8'>
            <div className='min-h-[80vh]'>
              <h1 className='font-bold text-xl text-center'>Nosotros</h1>    
              <div className='flex flex-col items-center justify-center py-8'>
                <div className='grid grid-cols-2 w-[90%] gap-2 h-full rounded-xl py-4'>
                  <img src={HeroNosotros} alt="" className='rounded-xl shadow-lg shadow-blue-500/50'/>
                  <img src={HeroNosotros2} alt="" className='rounded-xl shadow-lg shadow-blue-500/50'/>
                </div>
                <div className='w-[90%]'>
                  
                  <h1 className='font-bold text-3xl text-center py-2'>PLAYA NORTE</h1>
                  <p className='text-justify'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste nisi voluptatem fugiat modi veritatis at a iure architecto similique aut tenetur voluptatibus exercitationem, repudiandae eos quisquam temporibus quos! Voluptatum numquam voluptate dolore sapiente fugiat expedita ex perspiciatis, libero laboriosam dolores!</p>
                </div>
              </div>
            </div> 
            
        </div>
        <Footer/>
    </div>
  )
}

export default Nosotros